<template>
  <div class="flex flex-col">
    <div class="flex justify-between items-center">
      <VTitle :title="$t('app.instructors', 2)" class="route-title" />

      <SearchField
        :show-advanced-search="showAdvancedSearch"
        :field-options="fieldOptions"
        :is-searching="isSearching"
        @input="onUpdateSearch"
        @click:search="onClickSearch"
        @click:export="onClickExport"
        @update:showAdvancedSearch="showAdvancedSearch = $event"
      >
      </SearchField>
    </div>

    <AdvancedSearch
      v-if="showAdvancedSearch"
      :show-advanced-search="showAdvancedSearch"
      :field-options="fieldOptions"
      :is-searching="isSearching"
      :class="{ 'mb-10': showAdvancedSearch }"
      @click:search="onClickSearch($event)"
      @click:export="onClickExport"
    >
      <template #content="{ index, filters }">
        <FilterForm
          :index="index"
          :options="options"
          :types="types"
          @keyup:enter="onClickSearch(filters)"
        />
      </template>
    </AdvancedSearch>

    <slot>
      <router-view />
    </slot>
  </div>
</template>

<script>
import { onMounted, provide, ref } from "vue";
// Composables
import useUser from "@/composables/useUser";
import useSearch from "@/composables/useSearch";
// Components
import VTitle from "@/components/VTitle";
import SearchField from "@/components/SearchField";
import FilterForm from "@/components/templates/FilterForm";
import AdvancedSearch from "@/components/AdvancedSearch.vue";

export default {
  components: {
    VTitle,
    SearchField,
    FilterForm,
    AdvancedSearch
  },
  setup() {
    // Constants
    const route = "teaching-instructors";

    // DATA
    const showAdvancedSearch = ref(false);

    // Composables
    const { searchFields, view_code, options, types, getOptions } = useUser();
    const {
      fieldOptions,
      onClickSearch,
      isSearching,
      onUpdateSearch,
      onClickExport
    } = useSearch({
      types,
      searchFields,
      route,
      endpoint: "teaching.instructors"
    });

    // Provide
    provide("view_code", view_code);
    provide("types", types);

    // Lifecycle Hooks
    onMounted(async () => {
      await getOptions();
    });

    return {
      route,
      // useRole
      types,
      options,
      // useSearch
      fieldOptions,
      onClickSearch,
      isSearching,
      onUpdateSearch,
      onClickExport,
      showAdvancedSearch
    };
  }
};
</script>
