import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { required, email } from "@/i18n/i18n-validators";
// Composables
import useOptions from "@/composables/useOptions";
// Constants
import inputTypes from "@/constants/inputTypes";

export default function useUser() {
  // Misc
  const { t } = useI18n();

  const { ALL_OPTIONS, langs, getLicenses } = useOptions();

  // CONSTANTS
  const view_code = "Users";
  const route = "administration-users";
  const endpoint = "administration.users";
  const searchFields = ["firstname", "lastname", "username"];
  const logType = "User";
  const types = {
    username: inputTypes.TEXT,
    external_code: inputTypes.TEXT,
    firstname: inputTypes.TEXT,
    lastname: inputTypes.TEXT,
    title: inputTypes.TEXT,
    designations: inputTypes.TEXT,
    language: inputTypes.SELECT,
    phone: inputTypes.TEXT,
    email: inputTypes.TEXT,
    mobile: inputTypes.TEXT,
    details: inputTypes.TEXT,
    status: inputTypes.SELECT,
    license_id: inputTypes.SELECT,
    user_type: inputTypes.SELECT
  };
  const headers = [
    {
      value: "image",
      class: "w-10"
    },
    {
      text: t("app.users", 1),
      value: "name"
    },
    {
      text: t("app.phone"),
      value: "phone"
    },
    {
      text: t("app.email"),
      value: "email"
    },
    {
      text: t("app.status"),
      value: "status",
      class: "w-40"
    },
    {
      text: t("app.actions"),
      value: "__ACTIONS",
      class: "w-56"
    }
  ];

  // DATA
  const value = ref({
    username: null,
    external_code: null,
    firstname: null,
    lastname: null,
    title: null,
    designations: null,
    language: "",
    phone: null,
    email: null,
    mobile: null,
    details: "",
    status: ALL_OPTIONS.ACTIVE.value,
    license_id: null,
    user_type: ALL_OPTIONS.INTERNAL.value
  });
  const rules = ref({
    username: { required },
    external_code: {},
    firstname: { required },
    lastname: { required },
    title: {},
    designations: {},
    language: { required },
    phone: {},
    email: { required, email },
    mobile: {},
    details: {},
    status: {},
    license_id: { required },
    user_type: { required }
  });
  const options = ref({
    language: langs,
    status: [ALL_OPTIONS.ACTIVE, ALL_OPTIONS.INACTIVE, ALL_OPTIONS.DISABLED],
    license_id: [],
    user_type: [ALL_OPTIONS.INTERNAL, ALL_OPTIONS.EXTERNAL]
  });

  // METHODS
  const getOptions = async () => {
    options.value.license_id = await getLicenses();

    return options.value;
  };

  return {
    headers,
    logType,
    route,
    endpoint,
    searchFields,
    value,
    options,
    rules,
    types,
    view_code,
    getOptions,
    getLicenses
  };
}
